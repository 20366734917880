@import url('https://fonts.googleapis.com/css?family=Roboto+Condensed:300|Roboto:400&display=swap');

html,
body {
    font-family: 'Roboto', sans-serif;
    /* font-family: 'Roboto Condensed', sans-serif;     */
    line-height: 1.45;
    color: #333;
    margin: 0;
    height: 100%;
}


.ftr {
    position: fixed;
    bottom: 0;
    width: 100%;
}