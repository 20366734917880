@import url(https://fonts.googleapis.com/css?family=Roboto+Condensed:300|Roboto:400&display=swap);
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.dtListCell {
  margin-top: 6px;
}
html,
body {
    font-family: 'Roboto', sans-serif;
    /* font-family: 'Roboto Condensed', sans-serif;     */
    line-height: 1.45;
    color: #333;
    margin: 0;
    height: 100%;
}


.ftr {
    position: fixed;
    bottom: 0;
    width: 100%;
}
